<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          {{ clientAdmin ? 'Customer/Distributor' : (distributor ? 'Customer' : 'Distributor') }}
        </th>
        <th v-if="full">
          Asset
        </th>

        <th>Task</th>
        <th>Previous</th>
        <th>
          Due
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'AssetDetail',params: { assetId : item.assetId }}) }"
      >
        <td v-if="clientAdmin">
          <strong>Distributor:</strong> {{ item.distributorName }}<br/>
          <strong>Customer:</strong> {{ item.customerName }}
        </td>
        <td v-else>
          {{
            distributor ? item.customerName : item.distributorName
          }}
        </td>
        <td>
          <template v-if="item.assetTypeName">
            <div class="d-flex flex-row">
              <div class="flex-fill">
                <div class="font-weight-bold">{{ item.assetTypeName }}</div>
              </div>
              <div class="flex-wrap d-flex flex-column justify-center ml-2">
                <asset-type-dropdown :id="item.assetTypeId"/>
              </div>
            </div>
          </template>
          <div v-if="item.assetName || !item.organisationZoneName">
            <strong>Ref: {{ !item.organisationZoneName ? "Unallocated" : item.assetName }}</strong>
          </div>

        </td>

        <td class="py-3">
          <div>{{ item.checkName }}</div>
          <div>
            <check-timeline-chip short :check-timeline="item"></check-timeline-chip>
          </div>
        </td>
        <td class="py-3">
          <check-status v-if="item.lastCheckStatus" :status="item.lastCheckStatus"/>
          <div v-if="item.lastCheckDate && item.lastCheckStatus">{{ convertDate(item.lastCheckDate) }} <strong
              v-if="item.lastCheckUserName">by {{ item.lastCheckUserName }}</strong>
          </div>
        </td>


        <td>
          <v-chip :color="item.isDue ? 'accent' : ''">
            {{ item.period === 0 ? convertDateTime(item.nextDue) : convertDate(item.nextDue) }}
          </v-chip>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import SiteDropdown from "../sites/SiteDropdown";
import AssetTypeDropdown from "@/components/asset-types/AssetTypeDropdown";
import CheckStatus from "@/components/checks/CheckStatus";
import CheckTimelineChip from "@/components/CheckTimelineChip.vue";

export default {
  name: 'TaskPlannerList',
  components: {
    CheckTimelineChip,
    CheckStatus,
    SiteDropdown, AssetTypeDropdown, HoseConfigurationView, ActionDropdown
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),

  },
  methods: {
    convertDate(date) {
      return new moment(date).format("DD/MM/yyyy");
    },
    convertDateTime(date) {
      return new moment(date).format("DD/MM/yyyy HH:mm");
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-gesture',
          route: {name: 'AssetDetail', params: {assetId: item.asset.id}}
        }
      ];
      return data;
    }
  }
}
</script>
