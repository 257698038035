<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th >
          Asset
        </th>

        <th>
          Site & Zone
        </th>
        <th>
          Task
        </th>

      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'AssetDetail',params: { assetId : item.assetId }}) }"
      >
        <td  class="py-1">
          <template v-if="item.assetTypeName">

                <div class="font-weight-bold">{{ item.assetTypeName }}</div>

          </template>
          <div v-if="item.assetName">
            <strong>Ref : {{ item.assetName }}</strong>
          </div>

        </td>
        <td>
                    <span v-if="item.organisationZoneName">
                        <site-dropdown :title="item.organisationZoneName" :id="item.organisationZoneId"/>
                    </span>
          <strong v-else>
            Unallocated
          </strong>
        </td>
        <td>
          {{ item.checkName }}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import AssetTypeDropdown from "../asset-types/AssetTypeDropdown";
import SiteDropdown from "../sites/SiteDropdown";
import CheckStatus from "@/components/checks/CheckStatus";

export default {
  name: 'CheckList',
  components: {CheckStatus, SiteDropdown, AssetTypeDropdown, HoseConfigurationView, ActionDropdown},
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),

  },
  methods: {
    lastInspection(item) {
      return `<div>` + (item.lastInspectionDate ? "Inspected by " + item.lastInspectionName + " on " + moment(item.lastInspectionDate).format("DD/MM/yyyy") : "Never inspected") + `<div>`
    },
    nextInspection(item) {
      if (item.nextInspectionDate) {
        var divClass = "";
        var divText = "";
        var date = moment(item.nextInspectionDate);
        var currentDate = moment().utc();
        var soonDate = currentDate.subtract(1, "month");
        if (currentDate.isSameOrAfter(date)) {
          divClass = "error white--text";
          divText = "Inspection was due on " + date.format("DD/MM/yyyy");
        } else if (currentDate.isBefore(soonDate) && currentDate.isSameOrAfter(date)) {
          divClass = "warning white--text";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        } else {
          divClass = "light";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        }
        return `<span class="` + this.badgeClass + ` ` + divClass + `">` + divText + `</span>`
      }
      return "";
    }
  }
}
</script>
