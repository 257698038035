<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          {{ clientAdmin ? 'Customer/Distributor' : (distributor ? 'Customer' : 'Distributor') }}
        </th>
        <th>
          Asset
        </th>
        <th class="headerMin"v-if="full">
          Ref
        </th>
        <th class="headerMin">
          Task Result
        </th>
        <th class="headerMin" >
          Tasks Due
        </th>
        <th>

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'AssetDetail',params: { assetId : item.id }}) }"
      >
        <td v-if="clientAdmin">
          <strong>Distributor:</strong> {{ item.distributorName }}<br/>
          <strong>Customer:</strong> {{ item.customerName }}
        </td>
        <td v-else>
          {{ distributor ? item.customerName : item.distributorName }}
        </td>
        <td class="py-1">

          <template v-if="item.assetTypeName">
            <div class="d-flex flex-row">
              <div class="flex-fill">
                <div class="font-weight-bold">{{ item.assetTypeName }}</div>
              </div>
              <div class="flex-wrap d-flex flex-column justify-center ml-2">
                <asset-type-dropdown :id="item.assetTypeId"/>
              </div>
            </div>
          </template>
          <div>
            {{ item.checkCount }}/{{ item.maxChecks }} tasks
          </div>

        </td>

        <td class="py-1">
          <div v-if="item.name || !item.organisationZoneName">
            <div v-if="item.name && item.organisationZoneName == null">{{ item.name }}</div>
            <strong>{{ !item.organisationZoneName ? "Unallocated" : item.name }}</strong>
          </div>
        </td>


        <td class="py-1">
<!--                    <span v-if="item.organisationZoneName">-->
<!--                        <site-dropdown :title="item.organisationZoneName" :id="item.organisationZoneId"/>-->
<!--                    </span>-->
<!--          <strong v-else>-->
<!--            Uninstalled-->
<!--          </strong>-->
            <div class="mt-1" v-if="item.goodCount"><count-indicator :count="item.goodCount" word="complete" color="success"/></div>
            <div class="mt-1" v-if="item.monitorCount"><count-indicator :count="item.monitorCount" word="monitor" color="warning"/></div>
            <div class="mt-1" v-if="item.actionRequiredCount"><count-indicator :count="item.actionRequiredCount" word="action" color="error"/></div>


        </td>
        <td class="py-1">
            <count-indicator :count="item.dueCount" word="due" color="info"/>
        </td>

        <td class="text-right ">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import SiteDropdown from "../sites/SiteDropdown";
import AssetTypeDropdown from "@/components/asset-types/AssetTypeDropdown";
import CountIndicator from "@/components/assets/CountIndictor";

export default {
  name: 'AssetList',
  components: {
    CountIndicator,
    AssetTypeDropdown, SiteDropdown, ActionDropdown
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),

  },
  methods: {
    checkPercent(item) {
      return Math.ceil((item.checkCount / item.maxChecks) * 100);
    },
    lastInspection(item) {
      return `<div>` + (item.lastInspectionDate ? "Inspected by " + item.lastInspectionName + " on " + moment(item.lastInspectionDate).format("DD/MM/yyyy") : "Never inspected") + `<div>`
    },
    nextInspection(item) {
      if (item.nextInspectionDate) {
        var divClass = "";
        var divText = "";
        var date = moment(item.nextInspectionDate);
        var currentDate = moment().utc();
        var soonDate = currentDate.subtract(1, "month");
        if (currentDate.isSameOrAfter(date)) {
          divClass = "error white--text";
          divText = "Inspection was due on " + date.format("DD/MM/yyyy");
        } else if (currentDate.isBefore(soonDate) && currentDate.isSameOrAfter(date)) {
          divClass = "warning white--text";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        } else {
          divClass = "light";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        }
        return `<span class="` + this.badgeClass + ` ` + divClass + `">` + divText + `</span>`
      }
      return "";
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-gesture',
          route: {name: 'AssetDetail', params: {assetId: item.id}}
        }
      ];

      return data;
    }
  }
}
</script>
<style lang="scss">
.headerMin {
  min-width: 150px;
}
</style>
